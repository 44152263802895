import React, { useEffect, useRef } from 'react'
import videojs from 'video.js'
import 'videojs-mux'

import AuthStore from 'stores/auth'
import { useStore } from 'hooks'

/**
 * A Video Player meant for simple mux playing. Not meant to be used for a Story Video Post
 * or a Live Post, neilson, ga, or anything with advertisement. Plain Jane Videojs.
 * @prop {videoSource} Object memoize for best results
 */
const VideoPlayerLite = ({
  autoplay,
  videoSource,
  videoOptions = {},
  muxTrackingData,
  aspectRatio = false,
  onPlay
}) => {
  const authStore = useStore(AuthStore)
  const container = useRef()
  const player = useRef()

  const initializePlayer = () => {
    player.current.mux({
      debug: false,
      data: {
        player_name: 'Barstool Web',
        env_key: process.env.MUX_DATA_KEY
      },
      respectDoNotTrack: false
    })
    player.current.controls(true)
    player.current.playsinline(true)
    if (onPlay) player.current.on('play', onPlay)
    if (aspectRatio) player.current.aspectRatio(aspectRatio)
  }
  const muxData = () => {
    return {
      ...muxTrackingData,
      env_key: process.env.MUX_DATA_KEY || 'cvk1v6obaq000bvruglo2mrqb',
      viewer_user_id: authStore.userId ? String(authStore.userId) : undefined
    }
  }

  const setupPlayerEvents = () => {
    if (muxTrackingData) {
      player.current.mux.emit('videochange', muxData())
    }
  }

  const loadPlayer = () => {
    player.current.src(videoSource.sources)
    player.current.poster(videoSource.poster)
    player.current.load()
  }

  // simple setup and tear down on mount and unmount
  useEffect(() => {
    player.current = videojs(
      container.current,
      {
        preload: 'none',
        techOrder: ['chromecast', 'html5']
      },
      () => {
        initializePlayer()
        setupPlayerEvents()
        loadPlayer()
        if (autoplay) {
          player.current.play()
        }
      }
    )

    return () => {
      if (player.current) {
        if (onPlay) player.current.off('play', onPlay)
        player.current.dispose()
      }
    }
  }, [])

  // reset source and poster when the videoSource changes
  // for best results pass this component a memozied object for videoSource
  // that only changes when the video should be reloaded
  useEffect(() => {
    loadPlayer()
  }, [videoSource])

  return (
    <div data-vjs-player key={`videoPlayer-${videoSource.id}`} className='videoPlayer'>
      <video
        ref={container}
        className='videoPlayer__container video-js'
        title={videoSource.name}
        poster={videoSource.poster}
        controls
        playsInline
        {...videoOptions}
      />
    </div>
  )
}

export default VideoPlayerLite
