import { observable } from 'mobx'
import { CommonStore } from 'stores/common'
import { mediaApi } from 'api'

export const MediaStore = () => {
  return observable({
    ...CommonStore,
    api: mediaApi
  })
}

MediaStore.cacheKey = 'MediaStore'

export default MediaStore
