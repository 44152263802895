import React from 'react'
import Head from 'components/Head'
import Barstool500LandingScreen from 'components/Barstool500LandingScreen'
import { usePageView, useStore } from 'hooks'
import { getStaticWrapper } from 'utils'
import ConfigStore from 'stores/config'
import MediaStore from 'stores/media'

function Barstool500Page({ promoVideo }) {
  useStore(MediaStore, { current: promoVideo })
  usePageView()
  return (
    <>
      <Head
        noAutoZoom
        title='Barstool 500 Party | Barstool Sports'
        ogImage='/static/images/barstool500/social-share.jpg'
        description='Attend the Barstool 500 party, presented by the Barstool Sportsbook, featuring a live performance by Machine Gun Kelly and a DJ set by Diplo.'
      />
      <Barstool500LandingScreen />
    </>
  )
}

export const getStaticProps = getStaticWrapper(async () => {
  const config = await ConfigStore().findById('barstool-web')
  let promoVideo = null
  if (config.barstool500?.landing_page_video) {
    promoVideo = await MediaStore().findById(config.barstool500?.landing_page_video)
  }
  return {
    promoVideo
  }
})

export default Barstool500Page
