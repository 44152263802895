import React from 'react'
import GlobalStyles from 'components/GlobalStyles'
import MinimalFooter from 'components/MinimalFooter'

import { THEME } from 'utils'
import { useStore } from 'hooks'
import MediaStore from 'stores/media'
import VideoPlayerLite from 'components/VideoPlayerLite'

function Barstool500LandingScreen() {
  const mediaStore = useStore(MediaStore)
  const promoVideo = mediaStore.current
  return (
    <>
      <div className='page'>
        <section className='event-info'>
          <div className='event-logo'>
            {promoVideo ? (
              <VideoPlayerLite
                videoSource={{
                  sources: [promoVideo.primary_source],
                  name: promoVideo.title,
                  poster: promoVideo.thumbnail
                }}
                muxTrackingData={{
                  video_id: 'barstool-500',
                  video_variant_id: promoVideo.id,
                  video_title: 'Barstool 500',
                  video_series: 'Barstool 500',
                  video_stream_type: 'on-demand'
                }}
              />
            ) : (
              <img src='/static/images/barstool500/logo.png' />
            )}
          </div>
          <div className='cta-section'>
            <h2 className='cta-heading'>Barstool 500 Party</h2>
            <label>Live Performance By: </label>
            <h1>Machine Gun Kelly</h1>
            <label>DJ Set By: </label>
            <h1>Diplo </h1>
            <p>
              May 28th, 9PM, Grand park
              <br />
              19000 grand park blvd, westfield, in 46074
            </p>
            <a href='https://barstoolsportsbook.com/promotions/barstool-500-052221'>
              <button type='submit' className='submit-button'>
                Get your ticket now
              </button>
            </a>
          </div>
        </section>
        <section className='ticket-info'>
          <div>
            <h1>How to get a ticket</h1>
            <p>
              Get ready for a party like the Midwest has never seen. The first 3,000 people to place a $250 wager on any
              selected NBA Playoffs bets in the "Exclusives - Bet With Barstool - Barstool 500" section of the Barstool
              Sportsbook will get a ticket for admission to the event. Open bar included. Wager $10,000 for a table of
              10 in the VIP Section. Available in PA, MI, IL, IN.
            </p>
          </div>
        </section>
        <section className='sportsbook-info'>
          <div className='sportsbook-logo'>
            <img src='/static/images/sportsbook/sportsbook-logo.png' />
          </div>
          <h1>
            <span>GAMBLING PROBLEM? </span> <span>CALL 1-800-GAMBLER</span>
          </h1>
        </section>
      </div>
      <MinimalFooter
        backgroundColor='#0D1323'
        foregroundColor='white'
        linkToggles={{ termsOfUse: true, privacyPolicy: true }}
        fontSize='12px'
      />
      <GlobalStyles />
      <style global jsx>{`
        body {
          background-color: #0d1323;
        }
        .page {
          min-height: calc(100vh - 163px);
          height: auto;
          max-height: auto;
          width: 100vw;
          background-color: #0d1323;
          color: white;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
        }
        .event-info {
          display: flex;
          flex-wrap: wrap;
          grid-column-gap: 32px;
          width: 100%;
          max-width: 1200px;
          justify-content: center;
          align-items: flex-start;
          align-content: center;
          margin: auto;
        }
        .event-logo {
          flex: 0 1 580px;
          margin: 0;
        }
        .event-logo img {
          width: 100%;
          height: auto;
        }
        .cta-section {
          flex: 0 1 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: white;
          color: black;
          text-align: center;
          margin: 50px 32px 0;
          padding-bottom: 30px;
        }
        .cta-heading {
          width: 100%;
          padding: 0.5em;
          text-align: center;
          background: #f6f5f4;
          margin-top: 0;
          margin-bottom: 24px;
          font-size: 22px;
        }
        .cta-section h1,
        .cta-section h2 {
          font-family: ${THEME.FONTS.BEBAS};
          font-style: normal;
          font-weight: normal;
        }
        .cta-section h1 {
          margin: 10px 0 24px;
          font-size: 36px;
        }
        .cta-section label {
          text-transform: uppercase;
          letter-spacing: 1.2;
          margin: 0;
          font-weight: 500;
        }
        .cta-section p {
          font-family: ${THEME.FONTS.HELVETICA};
          font-weight: 500;
          font-size: 14px;
          line-height: 1.5;
          margin: 0 0 26px;
          text-transform: uppercase;
        }
        .cta-section a {
          width: 100%;
        }
        .cta-section button {
          width: 80%;
          margin: 10px auto;
          font-size: 12px;
        }
        .ticket-info {
          width: 100%;
          box-sizing: border-box;
          margin: 50px auto 0;
          background-color: white;
          color: ${THEME.COLORS.TEXTCOLOR};
        }
        .ticket-info div {
          padding: 48px 24px;
          max-width: 1200px;
          margin: 0 auto;
        }
        .ticket-info h1 {
          margin: 0 0 24px;
          font-family: ${THEME.FONTS.BEBAS};
          font-style: normal;
          font-weight: normal;
          font-size: 36px;
          color: ${THEME.COLORS.TEXTCOLOR};
        }
        .ticket-info p {
          margin: 0;
          font-size: 18px;
          line-height: 1.2;
          letter-spacing: 0.1px;
        }
        .sportsbook-info {
          width: 100%;
          padding: 0 32px;
          margin: 50px 0;
        }
        .sportsbook-info h1 {
          font-family: ${THEME.FONTS.BEBAS};
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          color: white !important;
          text-decoration: none !important;
          margin: 0 0 1rem auto;
          text-align: center;
          margin-top: 24px;
        }
        .sportsbook-info h1 span {
          display: inline-block;
        }
        .sportsbook-logo {
          width: 175px;
          margin: 0 auto 0 0;
        }
        .sportsbook-logo img {
          width: 100%;
          height: auto;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .event-logo {
            margin: 40px 0 0;
          }
          .cta-section {
            margin: 50px 32px;
          }
          .cta-section h1 {
            font-size: 48px;
          }
          .cta-section h2 {
            font-size: 32px;
          }
          .cta-section p {
            font-size: 16px;
          }
          .ticket-info {
            min-height: 300px;
            margin: 0 auto;
          }
          .ticket-info h1 {
            font-size: 30px;
          }
          .ticket-info p {
            font-size: 16px;
          }
          .sportsbook-info {
            margin: 50px auto 0;
          }
          .sportsbook-info h1 {
            font-size: 36px;
          }
          .sportsbook-logo {
            max-width: 410px;
          }
          .cta-section button {
            font-size: 14px;
          }
        }
        @media (min-width: 1112px) {
          .event-logo {
            margin: 100px 0 0;
          }
          .cta-section {
            margin: 80px auto 0;
            height: 435px;
            transform: translateY(20px);
            box-shadow: 0px 4px 14px rgba(55, 55, 55, 0.552147);
          }
        }
      `}</style>
    </>
  )
}

export default Barstool500LandingScreen
